import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql } from 'gatsby';
import _capitalize from 'lodash/capitalize';
import React from 'react';

import Layout from '../layouts/Layout';
import PostCards from '../components/PostCards';
import Seo from '../components/Seo';
import Breadcrumb from '../components/Breadcrumb';
import { rendererOptions } from '../utils';

const Article = ({ data }) => {
  const { article } = data;

  return (
    <Layout /* withSearch={true} */>
      <Seo
        title={article.title}
        description={article.description}
        lang={article.locale}
        path={`/help/${article.category.slug}/${article.slug}`}
      />

      <div className="px-4 sm:px-6 lg:px-16 mx-auto max-w-5xl prose kb">
        <Breadcrumb
          className="pl-0"
          paths={[
            { url: '/help', name: 'All categories' },
            {
              url: `/help/${article.category.slug}/`,
              name: article.category.name,
            },
          ]}
        />

        <article>
          <h1>{article.title}</h1>

          {article.plans && (
            <div
              className="flex md:inline-flex items-start md:items-center p-2 px-4 mb-4 w-full leading-none text-white bg-oxford-blue-100 rounded border-rhino-500"
              role="alert"
            >
              This feature is available on{' '}
              {article.plans.map((p) => (
                <span
                  key={p}
                  className="p-1 ml-2 text-sm text-white bg-oxford-blue rounded-lg"
                >
                  {_capitalize(p)}
                </span>
              ))}
            </div>
          )}

          {documentToReactComponents(
            JSON.parse(article.body.raw),
            rendererOptions({ locale: article.locale }, article.body.references)
          )}
        </article>
        {article.related && (
          <>
            <hr />
            <h2>Related</h2>
            <PostCards posts={article.related} />
          </>
        )}
      </div>
    </Layout>
  );
};

export default Article;

export const query = graphql`
  query Article($id: String) {
    article: contentfulKbAppArticle(id: { eq: $id }) {
      title
      slug
      description: metaDescription
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            title
            file {
              url
            }
          }
        }
      }
      plans
      category: kbAppCategory {
        slug
        name
      }
      related {
        ... on ContentfulBlog {
          metadata {
            tags {
              contentful_id
            }
          }
          title
          slug
          excerpt {
            internal {
              content
            }
          }
          featuredImage {
            title
            gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
          }
        }
        ... on ContentfulKbAppArticle {
          id
          category: kbAppCategory {
            slug
          }
          featuredImage {
            title
            gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
          }
          title
          slug
          metaDescription
        }
      }
      locale: node_locale
    }
  }
`;
