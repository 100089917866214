import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import Logo from '../assets/svg/logo.svg';

const PostCards = ({ posts = [] }) => {
  return (
    <div className="grid grid-cols-3 gap-4">
      {posts.map((post) => (
        <div key={post.slug} className="overflow-hidden rounded shadow-lg">
          <div>
            {post.featuredImage ? (
              <GatsbyImage
                alt={post.featuredImage?.title}
                image={post.featuredImage?.gatsbyImageData}
              />
            ) : (
              <div className="flex items-center bg-oxford-blue aspect-video">
                <Logo
                  alt="Copybara Logo"
                  className="my-8 mx-auto w-1/3 h-auto text-white"
                />
              </div>
            )}
          </div>
          <div className="py-4 px-6">
            <Link
              to={
                post?.metaDescription
                  ? `/help/${post.category.slug}/${post.slug}`
                  : `/blog/${post.slug}/`
              }
              className="mb-2 text-xl font-bold no-underline"
            >
              {post.title}
            </Link>
            <p className="my-2 text-base text-gray-700">
              {post?.metaDescription ?? post?.excerpt?.internal?.content}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PostCards;
