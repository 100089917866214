import clsx from 'clsx';
import React from 'react';
import { Link } from 'gatsby';

const Breadcrumb = ({ className, paths }) => {
  if (!Array.isArray(paths) || paths?.length === 0) return null;

  return (
    <ul className={clsx('inline-block mb-8', className)}>
      {paths.map((path) => (
        <li
          key={path.name.toLowerCase().replace(/\s+/, '-')}
          className="breadcrumb"
        >
          {path.url ? <Link to={path.url}>{path.name}</Link> : `${path.name}`}
        </li>
      ))}
    </ul>
  );
};

export default Breadcrumb;
